<template>
  <div v-element-sizes="{ id: 'window' }" class="layout-default">
    <SeoTemplatePreset>
      <NavigationMenu />
      <main>
        <slot />
      </main>
    </SeoTemplatePreset>
  </div>
</template>

<script setup>
const route = useRoute()
const fromError = setFromError()
const lenis = inject('lenis')

watch(
  () => route.path,
  () => {
    setTimeout(() => {
    lenis.value.raf(performance.now())
    lenis.value.scrollTo(0) // Forza un aggiornamento del valore dello scroll
  }, 500)
    setTimeout(() => {
             //lenis.value.raf(performance.now())
             window.dispatchEvent(new Event('resize'))
          }, 800)
   
    //animateMenuOut().play()

    /*if (isHover.value) {
      isHover.value = true
    }*/
  }
)
/*watch(
  () => fromError.value,
  (fromError) => {
    if (
      fromError
    ) {
      reloadNuxtApp() // è brutterrimo, bisogna trovare un fix sensato e più pulito
      
    } else {
      return
    }
  }
)*/


</script>
